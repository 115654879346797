import { getTrackingProvider } from './providers/providers';
import { getFoundElements } from './lib/get-found-elements';
import { isUrl } from './lib/is-url';
import { getLogger } from './lib/get-logger';

window.bbgi = {
    pixels: [],
    connect: () => {
        for (let idx = 0; idx < window.bbgi.pixels.length; idx++) {
            window.bbgi.pixels[idx].connect();
        }
    },
    disconnect: () => {
        for (let idx = 0; idx < window.bbgi.pixels.length; idx++) {
            window.bbgi.pixels[idx].disconnect();
        }
    },
};
window.bbgiConversionTracker = ({
    pixel,
    elementSelector,
    customProvider,
    target = '_self',
    connectOnLoad = true,
    onComplete,
    preventDefaultEvent = false,
    trackCondition,
}) => {
    let trackedElementsExistingHandler = [];
    let trackedElements = [];
    const log = getLogger();

    const handleClick = (e) => {
        if (isUrl(elementSelector) || preventDefaultEvent) {
            e.preventDefault();
        }

        if (trackCondition) {
            if (! trackCondition()) {
                return null;
            }
        }

        fireConversionPixel(parseInt(e.currentTarget.dataset.pixelIndex));
    };

    const callOriginalClickHandler = (idx) => {
        if (trackedElementsExistingHandler[idx]) {
            trackedElementsExistingHandler[idx].apply(trackedElements[idx]);
        }
    };

    const complete = (idx) => {
        log('Tracked Item');
        callOriginalClickHandler(idx);
        if (typeof elementSelector === 'string') {
            if (isUrl(elementSelector)) {
                window.open(elementSelector, target);
            }
        }
        if (onComplete && typeof onComplete === 'function') {
            onComplete();
        }
    };

    const matchedTrackingProvider =
        customProvider ?? getTrackingProvider({ value: pixel });

    const trackingProvider = matchedTrackingProvider(pixel, complete);

    function fireConversionPixel(idx) {
        trackingProvider.fire(idx);
    }

    const performScan = () => {
        performClean();
        let foundElements =
            typeof elementSelector === 'function'
                ? elementSelector()
                : getFoundElements(elementSelector);

        log(
            foundElements
                ? 'Submit Form Button ' + elementSelector + ' Found'
                : 'Submit ' + elementSelector + ' Button Not Found'
        );

        if (foundElements && foundElements.length && foundElements.length > 0) {
            trackedElements.push(...foundElements);
        } else if (foundElements && typeof foundElements.length === 'undefined') {
            trackedElements.push(foundElements);
        }

        if (trackedElements.length > 0) {
            for (let idx = 0; idx < trackedElements.length; idx++) {
                trackedElementsExistingHandler.push(
                    trackedElements[idx].onclick
                );

                trackedElements[idx].onclick = null;
                trackedElements[idx].dataset.pixelIndex = idx.toString();

                trackedElements[idx].addEventListener('click', handleClick);
            }
        }
    };

    const performClean = () => {
        for (let idx = 0; idx < trackedElements.length; idx++) {
            trackedElements[idx].removeEventListener('click', handleClick);
            if (trackedElementsExistingHandler[idx]) {
                trackedElements[idx].onclick =
                    trackedElementsExistingHandler[idx];
            }
            trackedElements = [];
            trackedElementsExistingHandler = [];
        }
    };

    window.addEventListener('load', () => {
        if (connectOnLoad) {
            performScan();
        }
    });

    const pixelcontrol = {
        connect: performScan,
        disconnect: performClean,
    };

    window.bbgi.pixels.push(pixelcontrol);

    return pixelcontrol;
};

window.bbgiConversionTrackerBatch = (config) => {
    const result = [];
    if (Array.isArray(config)) {
        for (let idx = 0; idx < config.length; idx++) {
            result.push(window.bbgiConversionTracker(config[idx]));
        }
    }

    return result;
};
