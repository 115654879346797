import {isUrl} from "./is-url";
import {isTel} from "./is-tel";

export function getFoundElements(elementSelector) {
    let query = elementSelector;
    const isUrlString = isUrl(elementSelector);
    const isTelString = isTel(elementSelector);

    if (isUrlString || isTelString) {
        query = `a[href='${elementSelector}']`;
    }

    const foundElements = document.querySelectorAll(query);

    return foundElements.length > 0 ? foundElements : null;
}
