export function configureSimplifyPixel(simplifiTagUrl, complete) {
    return {
        fire: (idx) => {
            const s = document.createElement('script');
            s.addEventListener('load', function () {
                complete(idx);
            });
            s.type = 'text/javascript';
            s.src = simplifiTagUrl;
            document.getElementsByTagName('head')[0].appendChild(s);
        },
    };
}