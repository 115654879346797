import {configureSimplifyPixel} from "./simplify";
import {configureDefaultPixel} from "./default";

export function getTrackingProvider(payload) {
    const providers = [
        {
            match: (val) => val.indexOf('simp') > -1,
            provider: configureSimplifyPixel,
        },
        {
            match: (val) => true,
            provider: configureDefaultPixel,
        },
    ];

    for (const provider of providers) {
        if (provider.match(payload.value)) {
            return provider.provider;
        }
    }
}