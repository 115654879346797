import {getLogger} from "../lib/get-logger";

export function configureDefaultPixel(id, complete) {
    return {
        fire: (idx) => {
            const log = getLogger()

            log(
                `default provider called. no value tracked. did not recognize ${id}`
            );
            complete(idx);
        },
    };
}
